<style lang="scss">
@import "~@/assets/css/var";

.my-star {
    .ts-table {
        display: flex;
        flex: 1;
        background: #fff;
        padding-right: 10px;
    }

    .my-table {
        padding: 20px 10px;
    }

}
</style>

<template>
    <div class="common-table my-star">
        <div class="common-table__title">
            <template v-if="$slots.default">
                <slot></slot>
            </template>
            <template v-else>
                我的笔记
            </template>
            <div class="flex" style="display: inline; margin-left: 20px;" v-if="searchCols && searchCols.length > 0">
                <div v-for="(col, index) in searchCols" :key="index" style="margin-right: 5px;">
                    <el-input v-if="col.listQueryModel === $variable.searchType.LIKE" style="width: 220px"
                        v-model="outSearchForm[col.fieldName]" :placeholder="'请输入' + col.name"></el-input>
                </div>
                <div style="margin-left: 5px;"><el-button type="primary" @click="outSearch">筛选</el-button></div>
            </div>
            <convenient-filtering :screenName="screenName" @searchTime="searchTime"></convenient-filtering>
            <ts-table-operate :cols="searchCols" @handleFilter="handleFilter" :hideSearch="true" :showExport="true"
                @leadingOut="exportInfo()">
                <el-select v-model="currentType" placeholder="请选择审核状态" style="width: 150px" @change="init">
                    <el-option value="" label="不限"></el-option>
                    <el-option value="0" label="药品"></el-option>
                    <el-option value="1" label="企业"></el-option>
                    <el-option value="2" label="企业药品"></el-option>
                </el-select>
            </ts-table-operate>
        </div>

        <div class="ts-table">
            <ts-table-edit class="make-table" :cols="cols" :data="list" :page-param="pageParam" :loading="loading"
                hide-detail full @handleSizeChange="handleSizeChange" :cmds="cmds" zero-to-empty
                @handleCurrentChange="handleCurrentChange" @handleSort="sort" :componentList="componentList"
                :indicationList="indicationList">
                <template v-slot:slot_comName="{ props }">
                    <div style="line-height: 1.6;padding: 5px 0;text-align: left">
                        <p>
                            <el-button type="text" @click="goSearchCom(props.row.comName)" v-show="props.row.comName">{{
                props.row.comName }}</el-button>
                        </p>
                        <p v-if="props.row.type === 2" style="margin-top: -10px;">
                            <el-button type="text" @click="goSearchComp(props.row.compName)"
                                style="font-size: 10px;padding:0" v-show="props.row.compName">{{ props.row.compName
                                }}</el-button>
                        </p>
                        <p v-else>
                            <el-button type="text" @click="goSearchComp(props.row.compName)"
                                v-show="props.row.compName">{{ props.row.compName }}</el-button>
                        </p>
                    </div>
                </template>
                <template v-slot:slot_winningPrice="{ props }">
                    <div v-if="props.row.winningPrice">
                        <p v-for="(item, index) in props.row.winningPrice.split('、')" :key="index">{{ item }}</p>
                    </div>
                    <p v-else>--</p>
                </template>
                <template v-slot:slot_context="{ props }">
                    <div v-if="props.row.sysUserNoteBookDTOList && props.row.sysUserNoteBookDTOList.length > 0">
                        <div v-for="(item, index) in props.row.sysUserNoteBookDTOList" :key="index">
                            <el-tooltip class="item" effect="light" :content="item.context" placement="top">
                                <p>{{ item.context }}</p>
                            </el-tooltip>
                        </div>
                    </div>
                    <p v-else>--</p>
                    <!-- <el-button style="line-height: 10px;padding: 7px 10px;" type="primary" round
                        @click="clickContext(props.row.sysUserNoteBookDTOList,'笔记内容')" size="mini">查看</el-button> -->
                </template>
            </ts-table-edit>
        </div>

        <!--详情弹窗-->
        <el-dialog class="detail-dialog" v-dialogDrag :visible.sync="detail.show" :show-close="false"
            :width="detail.full ? '100%' : '1130px'" append-to-body top="">
            <template #title>
                详情展示
                <span>
                    <i class="el-icon-full-screen" @click="detail.full = !detail.full"></i>
                    <i class="el-icon-close" @click="detail.show = false"></i>
                </span>
            </template>
            <ts-table-detail v-if="detail.show" :data="detail.data" :cols="detail.cols" :full="detail.full" :name="name"
                @showSearch="goComponentOrIndicationHandle" :componentList="componentList"
                :indicationList="indicationList">
            </ts-table-detail>
        </el-dialog>

        <drawer-content :drawerForm="drawerForm"></drawer-content>
    </div>
</template>

<script>
import MyStarJs from './MyStarJs'
import variable from "@/util/variable";
import TsTableEdit from '@/pages/make/TsTableEdit.vue'

export default {
    components: {
        TsTableEdit
    },
    mixins: [MyStarJs],
    data() {
        return {
            name: 'MyNote',
            visitId: new Date().getTime(),
            pageId: 'note',
            pageName: '我的笔记',

            loading: false,
            pageParam: {
                pageNo: 1,
                pageSize: variable.basePageSize,
                total: 0,
                error: '',
            },
            currentType: '',
            conditionList: [],
            list: [],
            cols: [
                { key: 'comName', value: '关注内容', width: 120, sort: true, align: 'tl', fixed: 'left', },
                // { key: 'compName', value: '企业名称', width: 200,sort:true,align:'tl'},
                { key: 'specNumber', value: '规格数', width: 60, sort: true, link: this.goChina, },
                { key: 'manufacturersNumber', value: '厂家数', width: 60, sort: true, link: this.goChina },
                { key: 'component', value: '药品成分', width: 150, sort: true, align: 'tl' },
                { key: 'indication', value: '功能主治', width: 150, sort: true, align: 'tl' },
                { key: 'usageDosage', value: '用法用量', width: 120, sort: true, align: 'tl' },
                { key: 'isBaseDrug', value: '基药', width: 60, sort: true, },
                { key: 'baseDrugSpec', value: '基药规格', width: 80, sort: true, align: 'tl' },
                { key: 'medicalInsurance', value: '医保', width: 60, sort: true, },
                { key: 'collectiveProcurement', value: '集采', width: 60, sort: true, link: this.goNation, },
                { key: 'winningProvince', value: '中标省份', width: 50, sort: true, link: this.goBid, },
                { key: 'winningPrice', value: '中标价格', width: 50, sort: true, },
                { key: 'devNumber', value: '研发', width: 60, sort: true, link: this.goRegister, },
                { key: 'consistencyEvaluation', value: '一致性', width: 70, sort: true, isIcon: true, iconText: '通过一致性评价' },
                { key: 'lastYearAmount', value: '近年销售金额', width: 100, sort: true, isIcon: true, iconText: '单位：【万元】' },
                { key: 'context', value: '笔记内容', width: 150, sort: true, align: 'tl', },
                { key: 'createdAt', value: '笔记时间', width: 90, sort: true },
            ],
            searchCols: [
                { key: 'comName', value: '药品通用名' },
                { key: 'compName', value: '企业名称' },
            ].map(item => ({
                id: item.key,
                fieldName: item.key,
                name: item.value,
                listQueryView: '是',
                listQueryModel: 0,
            })),
            cmds: [
                {
                    txt: '详情',
                    type: 'primary',
                    event: this.detailShow,
                    whether: () => true
                },
                {
                    txt: '新增',
                    type: 'success',
                    event: this.seeNote,
                    whether: () => true
                },
            ],


            rank1: [],
            rank2: [],
            screenName: 'createdAt',
            sortParam: {
                createdAt: 'desc'
            },
        }
    },
    created() {
        this.init()
        this.rank()

        this.$help.socket.send(this)
    },
    methods: {
        init() {
            this.pageParam.pageNo = 1
            this.getList()
        },
        getList() {
            this.loading = true
            this.$api.post('sys/sysUserNoteBook/querySysUserNoteBookPageGroup', {
                params: {
                    type: this.currentType || undefined
                },
                conditionList: this.conditionList,
                page: this.pageParam,
                sort: this.sortParam
            }, { 'Visit-Id': this.$help.getVisitId(this.visitId) })
                .then(res => {
                    if (res.success) {
                        this.pageParam.total = res.data.totalCount
                        this.list = res.data.records.map(item => {
                            switch (item.type) {
                                case 0:
                                    item.typeName = '药品'
                                    break
                                case 1:
                                    item.typeName = '企业'
                                    break
                                case 2:
                                    item.typeName = '企业药品'
                                    break
                            }
                            return item
                        })
                        this.list = this.listComputed(this.list)
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        handleFilter(conditionList) {
            this.conditionList = conditionList
                .filter(item => item.model)
                .map(item => {
                    return {
                        name: item.field.fieldName,
                        op: 'like',
                        value: item.model,
                    }
                })
            this.init()
        },
        handleSizeChange(val) {
            this.pageParam.pageSize = val
            this.handleCurrentChange(1)
        },
        handleCurrentChange(page) {
            this.pageParam.pageNo = page
            this.getList()
        },

        // 查看评价列表
        seeComment(row) {
            this.$store.commit('showComment', {
                type: row.type,
                comCode: row.comCode,
                comName: row.comName,
                compCode: row.compCode,
                compName: row.compName,
                cb: this.init,
                read: true,
            })
        },

        del(row) {
            this.$store.commit('confirm', {
                content: '确认取消关注？',
                callback: (next, back) => {
                    this.loading = true
                    this.$api.post('sys/sysUserAttention/deleteByIdList', [row.id])
                        .then(res => {
                            if (res.success) {
                                this.getList()
                                next()
                            } else {
                                this.loading = false
                            }
                        })
                        .catch(() => {
                            this.loading = false
                        })
                        .finally(back)
                }
            })
        },

        // 关注排行榜
        rank() {
            this.$api.get('sys/sysUserAttention/rankingList')
                .then(res => {
                    const list = res.data
                    this.rank1 = list.concat(new Array(10 - list.length).fill({}))
                })
            this.$api.get('sys/sysUserAppraise/rankingList')
                .then(res => {
                    const list = res.data
                    this.rank2 = list.concat(new Array(10 - list.length).fill({}))
                })
        },
        exportInfo(txt = '确定导出吗') {
            this.$help.exportInfo(txt, 'sys/sysUserNoteBook/exportThisUserSysUserNoteBook', {
                params: {
                    type: this.currentType || undefined
                },
                conditionList: this.conditionList,
            })
        },
        // 查看笔记列表
        seeNote(row) {
            const params = this.newParams(row)
            params.type = String(row.type)
            params.cb = this.getList
            this.$store.commit('showNote', params)
        },
    }
}
</script>