<style lang="scss">
@import "~@/assets/css/var";
.my-star {
    &__panel {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        opacity: 0;
        &.active {
            z-index: 1;
            opacity: 1;
        }
    }

    .ts-table {
        display: flex;
        flex: 1;
        background: #fff;
        padding-right: 10px;
    }
    .my-table {
        padding: 20px 10px;
    }

    .rank {
        padding: 20px 10px;
        &-panel {
            height: calc(100% - 50px);
            overflow: hidden;
            border-radius: 10px;
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
        }
        &-head {
            position: relative;
            text-align: left;
            padding: 15px;
            font-size: 22px;
            font-weight: bold;

            &:before {
                position: absolute;
                content: '';
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 4px;
                height: 20px;
                background: $main;
            }
            i {
                font-weight: bold;
                color: $main;
                margin-right: 5px;
            }
        }
        &-list {
            padding: 0 15px;
        }
        &-item {
            display: flex;
            align-items: center;
            padding: 10px;
            &:nth-child(odd) {
                background: #F6F7FB;
            }
            .left {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 20px;

                &.content {
                    width: 17px;
                    height: 17px;
                    align-items: normal;
                }

                &.active {
                    color: $main;
                }
            }
            .focus {
                flex: 1;
                padding: 0 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .useful {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        &-title {
            background: #E3EBFE !important;
        }
    }
}
</style>

<template>
    <div class="common-table" style="position:relative;">
        <my-star-star class="my-star__panel" :class="{active: type === 'star'}">
            <el-radio-group v-model="type">
                <el-radio-button label="star">我的关注</el-radio-button>
                <el-radio-button label="comment">我的评价</el-radio-button>
                <el-radio-button label="note">我的笔记</el-radio-button>
            </el-radio-group>
        </my-star-star>
        <my-star-comment class="my-star__panel" :class="{active: type === 'comment'}">
            <el-radio-group v-model="type">
                <el-radio-button label="star">我的关注</el-radio-button>
                <el-radio-button label="comment">我的评价</el-radio-button>
                <el-radio-button label="note">我的笔记</el-radio-button>
            </el-radio-group>
        </my-star-comment>
        <my-note class="my-star__panel" :class="{active: type === 'note'}">
            <el-radio-group v-model="type">
                <el-radio-button label="star">我的关注</el-radio-button>
                <el-radio-button label="comment">我的评价</el-radio-button>
                <el-radio-button label="note">我的笔记</el-radio-button>
            </el-radio-group>
        </my-note>
    </div>
</template>

<script>
import MyStarStar from './MyStarStar.vue'
import MyStarComment from './MyStarComment.vue'
import MyNote from './MyNote.vue'
export default {
    components: {
        MyStarStar,
        MyStarComment,
        MyNote
    },
    data() {
        return {
            type: 'star',
        }
    },
}
</script>